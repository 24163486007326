import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../app.service';
// Swal
import Swal from 'sweetalert2';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactUsComponent implements OnInit {
  form:FormGroup;
  submitted=false;
  loading=false;
  constructor(private formbuilder:FormBuilder, private service:AppService, public dialogRef: MatDialogRef<ContactUsComponent>) { }

  ngOnInit() {
    this.form = this.formbuilder.group({
      name:['',Validators.required],
      // email:['',Validators.required],
      email:['',[Validators.required,Validators.email]],

      phone:['',Validators.required],
      message:['',Validators.required]
    })
  }
  get f(){ return this.form.controls}
  submit(){
    this.submitted = true;
    if(this.form.invalid){return}
    this.loading = true;
    console.log(this.form.value)
    this.service.contactUs(this.form.value).subscribe(res=>{
      console.log(res)
      this.loading = false;
      this.dialogRef.close();
      Swal.fire({
        icon: 'success',
        title: 'تم ارسال الرسالة بنجاح',
        text: 'سيتم الرد عليكم في اقرب وقت',
      })
    }
    ,err=>{
      console.log(err)
      this.loading = false;
      // this.dialogRef.close();
      Swal.fire({
        icon: 'error',
        title: 'حدث خطأ ما',
        text: 'حاول مرة اخرى او تواصل معنا عبر البريد الالكتروني support@egforex.net',
      })
    })
  }
}
