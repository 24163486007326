import { Component } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ContactUsComponent } from './contact-us/contact-us.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'egforex';
  constructor(public dialog: MatDialog) { }

  contactUs(): void {
    const dialogRef = this.dialog.open(ContactUsComponent, {
      minWidth: '400px',
      // data: {name: this.name, animal: this.animal},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }
}
