import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
// Swal
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import Swal from 'sweetalert2';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private spinner: NgxSpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            this.spinner.hide()
            // 🤯
            switch (err.status) {
                case 400:
                    // Toast().fire({ icon: 'error', title: err.error.messages })
                    // try {
                    //     if(err.error?.msgs){
                    //         for (const [key, error] of Object.entries(err.error.msgs)) {
                    //                 Toast().fire({ icon: 'error', title: error })
                    //         }
                    //         // return 0
                    //     }
                    //     for (const [key, error] of Object.entries(err.error.messages)) {
                    //         if(error != "The token field is required.")
                    //             Toast().fire({ icon: 'error', title: error })
                    //     }
                    // } catch (error) {
                    //     Toast().fire({ icon: 'error', title: err.error.message })
                    // }
                    break;
               case 422:
                err.error.errors.forEach(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: error
                    })
                });
                    break;
                case 500:
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'حدث خطأ في الخادم . حاول لاحقًا'
                })

                    // Toast().fire({ icon: 'error', title: 'حدث خطأ في الخادم . حاول لاحقًا' })
                    break;
                default:
                    for (const [key, error] of Object.entries(err.error.message)) {
                        // Toast().fire({ icon: 'error', title: error })
                    }
                    break;
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}
